import React from 'react';
import PropTypes from 'prop-types';
import {CoreTextArea} from '../../../components/Form/Textarea';
import {QuestionsWrapper} from './styles';

export const ActionQuestions = ({handleAnswerChange, questions}) => {
    return (
        <>
            {questions.map(({id, question, answer}) => (
                <QuestionsWrapper key={id}>
                    <CoreTextArea
                        label={question}
                        name={`question-${id}`}
                        placeholder="Deine Antwort"
                        onChange={(e) => handleAnswerChange(id, e.target.value)}
                    />
                </QuestionsWrapper>
            ))}
        </>
    );
};

ActionQuestions.propTypes = {
    handleAnswerChange: PropTypes.func,
    questions: PropTypes.array,
};

import React, {useEffect, useReducer} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Markdown from 'markdown-to-jsx';
import Snowfall from 'react-snowfall';

import Layout from '../ui/layout/Layout';
import {Section, Container, Row, Column} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import Paragraph from '../ui/components/Paragraph';
import lang from '../lang/pages/aktionen.lang';
import Header from '../ui/layout/Header';
import NoCampaign from '../assets/images/aktionen/img-keine-aktion.png';
import AlreadyParticiapted from '../assets/images/aktionen/img-schon-teilgenommen.png';
import Image from '../ui/components/Image';
import FileUpload from '../ui/domains/sales-agent-user-interactions/UserCampaignSubmissions/FileUpload';
import CampaignInstructions from '../ui/domains/sales-agent-user-interactions/UserCampaignSubmissions/CampaignInstructions';
import {parseHtmlToReact} from '../utils/html-react-parser';
import CampaignsHero from '../assets/images/aktionen/img-hero.png';
import MarzipanText from '../assets/images/aktionen/img-marzipan-freude-geselligkeit.jpg';

import {
    selectCurrentCampaign,
    selectCurrentCampaignId,
    loadCurrentCampaign,
} from '../model/user-campaign-submissions';
import {
    loadSubmissionsSlider,
    selectSubmissionsSlider,
} from '../model/user-campaign-submissions-slider';
import ImageSlider from '../ui/domains/sales-agent-user-interactions/UserCampaignSubmissions/ImageSlider';
import TrainingTeaser from '../ui/domains/training/Teaser';
import ShopWindow from '../ui/layout/ShopWindow';
import {
    selectById as selectTrainingById,
    suggest as suggestTrainings,
} from '../model/training';
import {selectIsLoggedIn} from '../model/authentication';
import {selectUserCategories} from '../model/profile';
import {selectHasUserReachedPointLimit} from '../model/profile';
import {PointLimitMessage} from '../ui/domains/point-limit/PointLimitMessage';

import {updloadSubmissionImageFile} from '../model/user-campaign-submissions';
import {ActionQuestions} from '../ui/domains/action/questions';
import PropTypes from 'prop-types';
import ResponsiveSwitch from '../ui/layout/ResponsiveSwitch';

const questions = [
    {
        id: 1,
        question: 'Was verbindest Du mit der Marke GeloMyrtol® forte?',
    },
    {
        id: 2,
        question: 'Für wen ist Sobrade® eine gute Empfehlung?',
    },
    {
        id: 3,
        question:
            'In welchem Bundesland befindet sich der Hauptsitz von Pohl-Boskamp?',
    },
];

const initialState = {
    answer1: '',
    answer2: '',
    answer3: '',
};

const reducer = (state, action) => {
    switch (action.questionId) {
        case 1:
            return {...state, answer1: action.payload};
        case 2:
            return {...state, answer2: action.payload};
        case 3:
            return {...state, answer3: action.payload};
        default:
            return state;
    }
};

const Aktionen = () => {
    const [state, initialDispatch] = useReducer(reducer, initialState);

    const currentCampaign = useSelector(selectCurrentCampaign);
    const currentCampaignId = useSelector(selectCurrentCampaignId);
    const currentCampaignInspirationImages = useSelector(
        selectSubmissionsSlider
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadCurrentCampaign());
    }, [dispatch]);

    useEffect(() => {
        if (currentCampaignId) {
            dispatch(loadSubmissionsSlider(currentCampaignId));
        }
    }, [currentCampaignId, dispatch]);

    const participated =
        currentCampaign &&
        currentCampaign.submissionStatus &&
        currentCampaign.submissionStatus !== 'rejected';

    const trainingById = useSelector(selectTrainingById);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userCategories = useSelector(selectUserCategories);
    const {suggestedTrainings} = suggestTrainings(
        trainingById,
        isLoggedIn,
        userCategories
    );
    const hasUserReachedPointLimit = useSelector(
        selectHasUserReachedPointLimit
    );

    const handleAnswerChange = (questionId, answer) => {
        initialDispatch({questionId, payload: answer});
    };

    const isCompleted = Object.values(state).every((answer) => answer !== '');
    return (
        <Layout>
            {!currentCampaign && (
                <Header img={CampaignsHero} alt={lang.header.alt} paddingTop>
                    <Headline type="h1">{lang.header.headline}</Headline>
                    <Paragraph>{lang.header.paragraph}</Paragraph>
                </Header>
            )}
            {!currentCampaign ? (
                <>
                    <Section padding="2rem 0 0 0" noLineHeight>
                        <Container
                            width="narrow"
                            className="text-center"
                            noGutter>
                            <Headline type="h2">
                                {lang.noCampaignSection.headline}
                            </Headline>
                            <Paragraph>
                                {lang.noCampaignSection.paragraph}
                            </Paragraph>
                            <Image
                                src={NoCampaign}
                                alt={lang.noCampaignSection.alt}
                            />
                        </Container>
                    </Section>
                    <Section theme="grey">
                        <Container width="wide">
                            <Row>
                                <Column xs={12} className="text-center">
                                    <Headline type="h2">
                                        {lang.noCampaignTrainingSlider.headline}
                                    </Headline>
                                    <Paragraph>
                                        {
                                            lang.noCampaignTrainingSlider
                                                .paragraph
                                        }
                                    </Paragraph>
                                </Column>
                                <Column xs={12}>
                                    <ShopWindow teasers={suggestedTrainings}>
                                        {(training) => (
                                            <TrainingTeaser
                                                title={training.title}
                                                type={training.type.name}
                                                image={training.image}
                                                points={training.points}
                                                duration={training.duration}
                                                completed={training.completed}
                                                route={training.route}
                                                isReleased={training.isReleased}
                                            />
                                        )}
                                    </ShopWindow>
                                </Column>
                            </Row>
                        </Container>
                    </Section>
                </>
            ) : participated ? (
                <Section padding="2rem 0 0 0" noLineHeight>
                    <Container width="narrow" className="text-center" noGutter>
                        <Headline type="h2">
                            {lang.participatedSection.headline}
                        </Headline>
                        <Paragraph>
                            {lang.participatedSection.paragraph}
                        </Paragraph>
                        <Image
                            src={AlreadyParticiapted}
                            alt={lang.participatedSection.alt}
                        />
                    </Container>
                </Section>
            ) : (
                <>
                    <Snowfall
                        snowflakeCount={200}
                        color="white"
                        style={{height: 600, position: 'absolute', zIndex: 800}}
                    />
                    <ResponsiveSwitch>
                        {(match) => {
                            if (match.xl) {
                                return (
                                    <Section theme="blue">
                                        <Container width="wide" noGutter>
                                            <Image
                                                src={MarzipanText}
                                                alt=""
                                                width="100%"
                                            />
                                        </Container>
                                    </Section>
                                );
                            } else {
                                return (
                                    <Section theme="blue">
                                        <Container noGutter>
                                            <Image
                                                src={MarzipanText}
                                                alt=""
                                                width="100%"
                                            />
                                        </Container>
                                    </Section>
                                );
                            }
                        }}
                    </ResponsiveSwitch>
                    {/* <Header
                        fullHeader
                        photo
                        img={currentCampaign.headerImage}
                        alt={lang.header.alt}
                        paddingTop>
                        <Headline type="h1">
                            {parseHtmlToReact(currentCampaign.headerTitle)}
                        </Headline>
                        <Paragraph>
                            {parseHtmlToReact(
                                currentCampaign.headerDescription
                            )}
                        </Paragraph>
                    </Header> */}
                    <Section>
                        <Container width="wide">
                            <Row>
                                <Column xl={6}>
                                    <Headline type="h2">
                                        {parseHtmlToReact(
                                            currentCampaign.headline
                                        )}
                                    </Headline>
                                    <Markdown>
                                        {currentCampaign.description}
                                    </Markdown>
                                </Column>
                                <Column xl={6}>
                                    {lang.campaignSection.steps.map(
                                        (step, index) => {
                                            return (
                                                <CampaignInstructions
                                                    key={index}
                                                    image={step.image}
                                                    headline={step.headline}>
                                                    {step.paragraph}
                                                </CampaignInstructions>
                                            );
                                        }
                                    )}
                                </Column>
                            </Row>
                        </Container>
                    </Section>
                    <Section>
                        <Container width="wide">
                            <ActionQuestions
                                handleAnswerChange={handleAnswerChange}
                                questions={questions}
                            />
                        </Container>
                    </Section>
                    {isCompleted &&
                        (hasUserReachedPointLimit ? (
                            <PointLimitMessage theme="grey" />
                        ) : (
                            <ConnectedFileUpload
                                theme="grey"
                                dropzoneText={lang.dragAndDropSection.paragraph}
                                state={state}
                            />
                        ))}
                </>
            )}
            {currentCampaign && currentCampaignInspirationImages.length ? (
                <Section theme={participated ? 'grey' : null}>
                    <Container width="xtra-wide" className="text-center">
                        <Headline type="h2">
                            {lang.campaignSubmissionsSection.headline}
                        </Headline>
                        <Paragraph>
                            {lang.campaignSubmissionsSection.copy}
                        </Paragraph>
                        <ImageSlider
                            isHostUrl={false}
                            images={currentCampaignInspirationImages}
                        />
                    </Container>
                </Section>
            ) : null}
        </Layout>
    );
};

const ConnectedFileUpload = (props) => {
    const dispatch = useDispatch();

    const handleSubmit = (imageFile) => {
        dispatch(updloadSubmissionImageFile(imageFile, props.state));
    };

    return <FileUpload {...props} onSubmit={handleSubmit} />;
};

ConnectedFileUpload.propTypes = {
    state: PropTypes.object.isRequired,
};

export default Aktionen;
